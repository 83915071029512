import "./TwoColumnList.css";

// Generates a row with Key: Value - so we can make nice looking lists

const TwoColumnList = ({ property, value }) => {

    return (

        <div className="two-column-list__row">

            <span className="two-column-list__key">{property}</span>

            <span className="two-column-list__value">{value}</span>

        </div>

    );

};

export default TwoColumnList;