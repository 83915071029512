// Import react elements
import { useContext, useState, useEffect } from "react";

// Import strich api calls
import { getOrders } from "api/APIStrichCalls";

// Import context function
import { AuthContext } from "global/context/AuthContext";

// Import hooks
import usePageTitle from "global/hooks/usePageTitle";

// Import components
import { Grid } from "global/components/Grid";
import OrdersList from "./components/OrdersList";



// OrdersPage.js
const Orders = () => {



    // Set title inside the page
    usePageTitle("Orders"); 



    // Get the token from AuthContext
    const { token, authReady } = useContext(AuthContext);



    return (

        <Grid>

            {OrdersList(token, authReady)}

        </Grid>

    );
};

export default Orders;