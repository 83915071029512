// Import API call
import { strichCall } from './APICoreCalls';



export function getUserAccountData(token, set_account_details) {

    strichCall("GET", "accounts/account", token)
        .then((response) => {
            //console.log(response)
            set_account_details(response["results"]);
        })

    return;
}

export function getConnections(token, set_connections_details) {

    strichCall("GET", "connections/connections", token)
        .then((response) => {
            //console.log(response)
            set_connections_details(response["results"]);
        })

    return;
}




// Request Change Account Email Address
export async function requestEmailChange(token) {

    const response = await strichCall("GET", "accounts/request_email_change", token);

    //console.log(" Response:", response);

    return response;

}









// Get orders
export async function getOrders(token) {

    const response = await strichCall("GET", "orders/orders", token);

    console.log("Get Orders Response:", response);

    return response;

}

// Get specific order details
export async function getOrder(token, orderId) {

    const response = await strichCall("GET", "orders/order?order_id=" + orderId, token);
      
    console.log("Get Order Details Response:", response);

    return response;

}

// Update Order
export async function updateOrder(token, updated_order_details) {

    // Convert the data to correct format for the call
    const updatedOrderDetails = JSON.stringify(Object.assign({}, updated_order_details))

    //console.log(updatedOrderDetails);

    try {

        const response = await strichCall("PUT", "orders/order", token, updatedOrderDetails);

        console.log(" Response:", response);

        // If response?.results is null or undefined, fall back to response - depending on what the response is 
        return response?.results ?? response;


    } catch (error) {

        //console.error(" API Error:", error);
        return { success: false, error: "Failed to update order." };

    }
}

// Add New Order
export async function addNewOrder(token, new_order_details) {

    // Convert the data to correct format for the call
    const newOrderDetails = JSON.stringify(Object.assign({}, new_order_details))

    console.log(newOrderDetails);

    try {

        const response = await strichCall("POST", "orders/order", token, newOrderDetails);

        console.log("addNewOrder response: ", response);

        return response;

    } catch (error) {

        console.error(" API Error:", error);
        return { success: false, error: "Failed to add order." };

    }
}

// Request Fulfilment Cost
export async function getFulfilmentCost(token, orderId) {

    const response = await strichCall("GET", "orders/get_fulfilment_cost?order_id=" + orderId, token);

    console.log("getFulfilmentCost Response:", response);

    return response;

}
// Send order for fulfilment
export async function sendForFulfilment(token, order_id, order_items) {

    // Set the postdata 
    let update_data = [];
    update_data["id"] = order_id
    update_data["order_items"] = order_items

    // Convert to JSON
    let postdata = JSON.stringify(Object.assign({}, update_data))

    try {

        const response = await strichCall("POST", "orders/send_for_fulfilment", token, postdata);

        console.log("send for fulfilment response: ", response);

        return response;

    } catch (error) {

        console.error(" API Error:", error);
        return { success: false, error: "Failed to send for fulfilment." };

    }
}












// Update Connection
export async function updateConnection(token, updated_connection_details) {

    // Convert the data to correct format for the call
    const updatedConnectionDetails = JSON.stringify(Object.assign({}, updated_connection_details))

    //console.log(updatedConnectionDetails);

    try {

        const response = await strichCall("PUT", "connections/connections", token, updatedConnectionDetails);

        //console.log(" Response:", response);
        // If response?.results is null or undefined, fall back to response - depending on what the response is 
        return response?.results ?? response;

    } catch (error) {

        //console.error(" API Error:", error);
        return { success: false, error: "Failed to update connection." };

    }
}
// Add New Connection
export async function addNewConnection(token, new_connection_details) {

    // Convert the data to correct format for the call
    const newConnectionDetails = JSON.stringify(Object.assign({}, new_connection_details))

    //console.log(newConnectionDetails);

    try {

        const response = await strichCall("POST", "connections/connections", token, newConnectionDetails);

        return response;

    } catch (error) {

        //console.error(" API Error:", error);
        return { success: false, error: "Failed to add connection." };

    }
}

