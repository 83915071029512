// Import react elements
import { useEffect } from "react";

// Import core functions
import { updateOrder } from "api/APIStrichCalls";
import { addNewOrder } from "api/APIStrichCalls";

// Import components
import TwoColumnList from "./TwoColumnList";
import { Grid, GridItem } from "global/components/Grid";
import CreateButton from "global/components/CreateButton";

// Import global hooks
import useFormHandler from "global/hooks/useFormHandler";

// Import utility functions
import { DisplayConfirmationDialog } from "global/utilities/DialogBox";

const OrderDetailsPopup = ({ isOpen, onClose, orderDetails, loadingOrderDetails, error, addingNewOrder, setAddingNewOrder, handleRequestFulfilment }) => {


    //console.log("Order Details Pop Up Info: ", orderDetails);

    //console.log("boolean: ", addingNewOrder);


    // Extract form-related state and functions from the `useFormHandler` custom hook
    const {

        formValues,     // Stores the current values of form inputs
        handleChange,   // Function to update form values when inputs change
        handleSubmit,   // Function to handle form submission logic
        resetForm,
        isFormPartlyEdited,
        fieldErrors,    // Object storing validation errors for individual fields
        formError,      // General error message (e.g. API errors)
        loading,        // Boolean indicating whether the form is submitting
        apiResponse,    // Stores the response from the API after submission

    } = useFormHandler({

        // Default values for the form fields
        initialValues: orderDetails ? orderDetails : "",

        // Enable syncing - because we're using memo to remeber the initial form values before the user edits them
        syncInitialValues: true,

        // API function to be called on form submission
        apiCall: addingNewOrder ? addNewOrder : updateOrder,

        // We want to convert boolean to string
        convertBooleans: true,

        // Validation function to check the form fields before submission
        validate: (values) => {

            // Set errors to empty
            const errors = {};

            // Validation rules


            // Return validation errors (if any)
            return errors;
        },
    });



    // useEffect runs whenever `apiResponse` changes
    useEffect(() => {

        // Check if there is a response
        if (apiResponse) {

            // Check if it is successful
            if (apiResponse.success) {

                // Check if we're adding a new order
                if (addingNewOrder) {

                    // It's a new order

                    // Display a confrimation dialog
                    DisplayConfirmationDialog(

                        // Dialog message
                        "A new order has been added.",

                        // Confirm button title
                        "OK",

                        // Cancel button title - empty because we don't want a cancel button
                        "",

                        // Returned response from the dialog box
                        function (is_confirmed) {

                            // Check if the response is TRUE
                            if (is_confirmed) {

                                // It is

                                // Set to false beacuse we're not adding a new order anymore
                                setAddingNewOrder(false);

                                // Close the pop up
                                onClose();

                            }
                        }

                    );

                } else {

                    // We're updating an existing order

                    // Display a confrimation dialog
                    DisplayConfirmationDialog(

                        // Dialog message
                        "The order has been updated.",

                        // Confirm button title
                        "OK",

                        // Cancel button title - empty because we don't want a cancel button
                        "",

                        // Returned response from the dialog box
                        function (is_confirmed) {

                            // Check if the response is TRUE
                            if (is_confirmed) {

                                // It is

                                // Set setEdittingOrder state to false beacuse we're not editing the order anymore
                                //setEdittingOrder(false);

                            }

                        }

                    );

                    /* Not sure if we need this to revert back to original data if cancel editting...?
                    // Wrap async logic in a self-invoking async function
                    (async () => {

                        // Set the ID we want to restore after the refresh
                        setReselectConnectionId(selectedConnection?.id);

                        // Await refresh userData from UserContext
                        await refreshUserData();

                    })();
                    */

                }

            }

        }

    }, [apiResponse]);







    // Add orderitems prices together and format it nicely (£ and 2 decimal places)
    const totalPriceFormatted = (value_type) => {

        // Return a formatted string of a the total, example: £12.00
        return (

            // Use reduce to loop through each order item and accumulate the total
            `£${orderDetails.orderitems?.reduce(

                // Set 'sum' as the accumulator that stores the running total
                (sum, item) =>

                    // Access the property (e.g. item["price"]) and convert to a number (or set as 0)
                    sum + Number(item[value_type] || 0),

                // Initial value to start with
                0

                // Format to 2 decimal places
            ).toFixed(2)}`

        )

    };












    // If isOpen is false return early (don't show the pop up)
    if (!isOpen) return null;

    // Else show the pop up
    return (

        <div className="popup-overlay">

            <div className="popup-container popup-container--large">

                <button className="popup-close" onClick={onClose}>X</button>

                <h2>Order Details</h2>

                <br />

                <div className="popup-content">               
               
                {loadingOrderDetails && <p>Loading...</p>}
                {error && <p style={{ color: "red" }}>{error}</p>}

                    {!loadingOrderDetails && !error && orderDetails && (

                        <>
                            <form onSubmit={handleSubmit}>

                                <Grid className={"popup_grid-container"} >

                                    <GridItem>
                                        <div>
                                            <h3>Overview</h3>

                                            <input
                                                name="id"
                                                type="text"
                                                value={formValues.id || ""}
                                                onChange={handleChange}
                                                disabled={!addingNewOrder}
                                                placeholder="Order ID"
                                            />

                                            <input
                                                name="venueorderid"
                                                type="text"
                                                value={formValues.venueorderid || ""}
                                                onChange={handleChange}
                                                disabled={!addingNewOrder}
                                                placeholder="Venue Order ID"
                                            />

                                            <input
                                                name="connid"
                                                type="text"
                                                value={formValues.connid || ""}
                                                onChange={handleChange}
                                                disabled={!addingNewOrder}
                                                placeholder="Connection ID"
                                            />
                                            <input
                                                name="status"
                                                type="text"
                                                value={formValues.status || ""}
                                                onChange={handleChange}
                                                disabled={!addingNewOrder}
                                                placeholder="Status"
                                            />


                                            <TwoColumnList property={<label>Order ID:</label>} value={<input type="text" defaultValue={orderDetails.id || ""} placeholder="Order ID" disabled={!addingNewOrder} />} />

                                            <TwoColumnList property={<label>Venue Order ID</label>} value={<input type="text" defaultValue={orderDetails.venueorderid || ""} placeholder="Venue Order ID" disabled />} />
                                            <TwoColumnList property={<label>Status</label>} value={<input type="text" defaultValue={orderDetails.status || ""} placeholder="Status" disabled />} />
                                            <TwoColumnList property={<label>Payment Method</label>} value={<input type="text" defaultValue={orderDetails.paymentmethod || ""} placeholder="Payment Method" disabled />} />
                                            <TwoColumnList property={<label>Packed By</label>} value={<input type="text" defaultValue={orderDetails.batchid || ""} placeholder="Packed By" disabled />} />
                                        </div>
                                    </GridItem>

                                    <GridItem>

                                        <div>
                                            <h3>Order Progress</h3>
                                            <TwoColumnList property={<label>Created</label>} value={<input type="text" defaultValue={orderDetails.creationdate || ""} placeholder="Created" disabled />} />
                                            <TwoColumnList property={<label>Paid</label>} value={<input type="text" defaultValue={orderDetails.paiddate || ""} placeholder="Paid" disabled />} />
                                            <TwoColumnList property={<label>Captured</label>} value={<input type="text" defaultValue={orderDetails.capturedate || ""} placeholder="Captured" disabled />} />
                                            <TwoColumnList property={<label>Altered</label>} value={<input type="text" defaultValue={orderDetails.altereddate || ""} placeholder="Altered" disabled />} />
                                            <TwoColumnList property={<label>Printed</label>} value={<input type="text" defaultValue={orderDetails.printeddate || ""} placeholder="Printed" disabled />} />
                                            <TwoColumnList property={<label>Shipped</label>} value={<input type="text" defaultValue={orderDetails.shippeddate || ""} placeholder="Shipped" disabled />} />
                                            <TwoColumnList property={<label>Cancelled</label>} value={<input type="text" defaultValue={orderDetails.cancelleddate || ""} placeholder="Cancelled" disabled />} />
                                            <TwoColumnList property={<label>Refunded</label>} value={<input type="text" defaultValue={orderDetails.refundeddate || ""} placeholder="Refunded" disabled />} />
                                        </div>

                                    </GridItem>

                                    <GridItem>

                                        <div>
                                            <h3>Shipping</h3>
                                            <TwoColumnList property={<label>Shipping Method</label>} value={<input type="text" defaultValue={orderDetails.chosenshippingmethod || ""} placeholder="Shipping Method" disabled />} />
                                            <TwoColumnList property={<label>Weight</label>} value={<input type="text" defaultValue={orderDetails.orderweight || ""} placeholder="Weight" disabled />} />
                                            <TwoColumnList property={<label>Cost (Excl. Tax)</label>} value={<input type="text" defaultValue={`£${Number(orderDetails.shippingcostexctax).toFixed(2)}` || ""} placeholder="0.00" disabled />} />
                                            <TwoColumnList property={<label>Cost (Tax)</label>} value={<input type="text" defaultValue={`£${Number(orderDetails.shippingcosttax).toFixed(2)}` || ""} placeholder="0.00" disabled />} />
                                            <TwoColumnList property={<label>Cost (Incl. Tax)</label>} value={<input type="text" defaultValue={`£${Number(orderDetails.shippingcostinctax).toFixed(2)}` || ""} placeholder="0.00" disabled />} />
                                            <TwoColumnList property={<label>Tracking Number</label>} value={<input type="text" defaultValue={orderDetails.trackingnumber || ""} placeholder="Tracking Number" disabled />} />
                                            <TwoColumnList property={<label>Tracking Status</label>} value={<input type="text" defaultValue={orderDetails.tracking_status || ""} placeholder="Tracking Status" disabled />} />
                                            <TwoColumnList property={<label>Tracking Notes</label>} value={<input type="text" defaultValue={orderDetails.tracking_summary || ""} placeholder="Tracking Notes" disabled />} />
                                            <TwoColumnList property={<label>Last Updated</label>} value={<input type="text" defaultValue={orderDetails.tracking_updated || ""} placeholder="Last Updated" disabled />} />
                                        </div>

                                    </GridItem>


                                    <GridItem>
                                        <h3>Order Notes</h3>
                                        <br />
                                        {/* <input type="text" defaultValue={orderDetails.order_notes || ""} placeholder="Order notes..." disabled /> */}
                                        <textarea name="order_notes" rows="2" cols="30" defaultValue={orderDetails.order_notes || ""} disabled></textarea>
                                    </GridItem>

                                </Grid>



                                <Grid className={"popup_grid-container"}>

                                    <GridItem>
                                        <div>
                                            <h3>Billing Address</h3>
                                            <br />
                                            <TwoColumnList property={<label>Company Name</label>} value={<input type="text" defaultValue={orderDetails.billingcompanyname || ""} placeholder="Company Name" disabled />} />
                                            <TwoColumnList property={<label>Customer Name</label>} value={<input type="text" defaultValue={orderDetails.billingcustname || ""} placeholder="Customer Name" disabled />} />
                                            <TwoColumnList property={<label>Address 1</label>} value={<input type="text" defaultValue={orderDetails.billingaddr1 || ""} placeholder="Address 1" disabled />} />
                                            <TwoColumnList property={<label>Address 2</label>} value={<input type="text" defaultValue={orderDetails.billingaddr2 || ""} placeholder="Address 2" disabled />} />
                                            <TwoColumnList property={<label>Address 3</label>} value={<input type="text" defaultValue={orderDetails.billingaddr3 || ""} placeholder="Address 3" disabled />} />
                                            <TwoColumnList property={<label>Address 4</label>} value={<input type="text" defaultValue={orderDetails.billingaddr4 || ""} placeholder="Address 4" disabled />} />
                                            <TwoColumnList property={<label>Country</label>} value={<input type="text" defaultValue={orderDetails.billingcountry || ""} placeholder="Country" disabled />} />
                                            <TwoColumnList property={<label>Contact No.</label>} value={<input type="text" defaultValue={orderDetails.billingcontacttel || ""} placeholder="Contact No." disabled />} />
                                        </div>
                                    </GridItem>


                                    <GridItem>
                                
                                            <h3>Shipping Address</h3>
                                            <br />
                                            <TwoColumnList property={<label>Company Name</label>} value={<input type="text" defaultValue={orderDetails.deliverycompanyname || ""} placeholder="Company Name" disabled />} />
                                            <TwoColumnList property={<label>Customer Name</label>} value={<input type="text" defaultValue={orderDetails.deliverycustname || ""} placeholder="Customer Name" disabled />} />
                                            <TwoColumnList property={<label>Address 1</label>} value={<input type="text" defaultValue={orderDetails.deliveryaddr1 || ""} placeholder="Address 1" disabled />} />
                                            <TwoColumnList property={<label>Address 2</label>} value={<input type="text" defaultValue={orderDetails.deliveryaddr2 || ""} placeholder="Address 2" disabled />} />
                                            <TwoColumnList property={<label>Address 3</label>} value={<input type="text" defaultValue={orderDetails.deliveryaddr3 || ""} placeholder="Address 3" disabled />} />
                                            <TwoColumnList property={<label>Address 4</label>} value={<input type="text" defaultValue={orderDetails.deliveryaddr4 || ""} placeholder="Address 4" disabled />} />
                                            <TwoColumnList property={<label>Address 4</label>} value={<input type="text" defaultValue={orderDetails.deliverycountry || ""} placeholder="Address 4" disabled />} />
                                            <TwoColumnList property={<label>Contact No.</label>} value={<input type="text" defaultValue={orderDetails.deliverycontacttel || ""} placeholder="Contact No." disabled />} />
                                
                                    </GridItem>

                                </Grid>

                                <Grid className={"popup_grid-container"}>

                                    <GridItem className={"scroll-x"}>

                                        <h3>Order Items</h3>


                                        {orderDetails.fulfilments ? (

                                                <CreateButton title="Request fulfilment" size="m" onClick={() => handleRequestFulfilment(orderDetails)} />

                                            ) : "" }
                                        

                                        <div className="table-container">

                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Item ID</th>
                                                    <th>SKU</th>
                                                    <th>Qty</th>
                                                    <th>Add to Queue</th>
                                                    <th>Item Weight</th>
                                                    <th>Title</th>
                                                    <th>Excl. Tax</th>
                                                    <th>Tax</th>
                                                    <th>Incl. Tax</th>
                                                    <th>Produced Date</th>
                                                    <th>Customs Code</th>
                                                    <th>Message</th>
                                                </tr>
                                            </thead>

                                            <tbody>


                                                {/* Check if orderDetails.orderitems is greater than 0 */}

                                                {/* Map through the order items and create a table row */}

                                                {/* Else create empty table row */}



                                                
                                                {/* Map orderDetails.orderitems if available */}
                                                { Array.isArray(orderDetails?.orderitems) &&
                                                    orderDetails.orderitems.map((orderItem) => (

                                                        <tr key={orderItem.id}>
                                                            <td><input type="text" defaultValue={orderItem.id || ""} placeholder="Item ID" disabled /></td>
                                                            <td><input type="text" defaultValue={orderItem.sku || ""} placeholder="SKU" disabled /></td>
                                                            <td><input type="text" defaultValue={orderItem.orderquantity || ""} placeholder="0" disabled /></td>
                                                            <td><input type="text" defaultValue={orderItem.addtoqueue || ""} placeholder="Yes/No" disabled /></td>
                                                            <td><input type="text" defaultValue={orderItem.packedweight || ""} placeholder="0.00" disabled /></td>
                                                            <td><input type="text" defaultValue={orderItem.title || ""} placeholder="Title" disabled /></td>
                                                            <td><input type="text" defaultValue={orderItem.itempriceexctax || ""} placeholder="0.00" disabled /></td>
                                                            <td><input type="text" defaultValue={orderItem.itempricetax || ""} placeholder="0.00" disabled /></td>
                                                            <td><input type="text" defaultValue={orderItem.itempriceinctax || ""} placeholder="0.00" disabled /></td>
                                                            <td><input type="text" defaultValue={orderItem.produced || ""} placeholder="Yes/No" disabled /></td>
                                                            <td><input type="text" defaultValue={orderItem.customscode || ""} placeholder="Customs Code" disabled /></td>
                                                            <td><input type="text" defaultValue={orderItem.customermessage || ""} placeholder="Message" disabled /></td>
                                                        </tr>

                                                    ))
                                                }

                                                {/* Map orderDetails.fulfilments[*].order.orderitems if available */}
                                                { Array.isArray(orderDetails?.fulfilments) &&

                                                    orderDetails.fulfilments.flatMap((fulfilment, fulfilmentIndex) =>
                                                        Array.isArray(fulfilment.order?.orderitems)
                                                            ? fulfilment.order.orderitems.map((fulfilmentItem, itemIndex) => (

                                                                <tr key={`f${fulfilmentIndex}-i${itemIndex}`}>
                                                                    <td><input type="text" defaultValue={fulfilmentItem.id || ""} placeholder="Item ID" disabled /></td>
                                                                    <td><input type="text" defaultValue={fulfilmentItem.sku || ""} placeholder="SKU" disabled /></td>
                                                                    <td><input type="text" defaultValue={fulfilmentItem.orderquantity || ""} placeholder="0" disabled /></td>
                                                                    <td><input type="text" defaultValue={fulfilmentItem.addtoqueue || ""} placeholder="Yes/No" disabled /></td>
                                                                    <td><input type="text" defaultValue={fulfilmentItem.packedweight || ""} placeholder="0.00" disabled /></td>
                                                                    <td><input type="text" defaultValue={fulfilmentItem.title || ""} placeholder="Title" disabled /></td>
                                                                    <td><input type="text" defaultValue={fulfilmentItem.itempriceexctax || ""} placeholder="0.00" disabled /></td>
                                                                    <td><input type="text" defaultValue={fulfilmentItem.itempricetax || ""} placeholder="0.00" disabled /></td>
                                                                    <td><input type="text" defaultValue={fulfilmentItem.itempriceinctax || ""} placeholder="0.00" disabled /></td>
                                                                    <td><input type="text" defaultValue={fulfilmentItem.produced || ""} placeholder="Yes/No" disabled /></td>
                                                                    <td><input type="text" defaultValue={fulfilmentItem.customscode || ""} placeholder="Customs Code" disabled /></td>
                                                                    <td><input type="text" defaultValue={fulfilmentItem.customermessage || ""} placeholder="Fulfilment Message" disabled /></td>
                                                                </tr>

                                                            ))

                                                            : []
                                                    )
                                                }

                                                {(!Array.isArray(orderDetails?.orderitems) ||
                                                    orderDetails.orderitems.length === 0) &&
                                                    (!Array.isArray(orderDetails?.fulfilments) ||
                                                        !orderDetails.fulfilments.some((f) => Array.isArray(f.order?.orderitems))) ? (

                                                    // If no order items or fulfilment order items create empty row 
                                                    <tr>
                                                        <td><input type="text" defaultValue="" placeholder="Item ID" disabled /></td>
                                                        <td><input type="text" defaultValue="" placeholder="SKU" disabled /></td>
                                                        <td><input type="text" defaultValue="" placeholder="0" disabled /></td>
                                                        <td><input type="text" defaultValue="" placeholder="Yes/No" disabled /></td>
                                                        <td><input type="text" defaultValue="" placeholder="0.00" disabled /></td>
                                                        <td><input type="text" defaultValue="" placeholder="Title" disabled /></td>
                                                        <td><input type="text" defaultValue="" placeholder="0.00" disabled /></td>
                                                        <td><input type="text" defaultValue="" placeholder="0.00" disabled /></td>
                                                        <td><input type="text" defaultValue="" placeholder="0.00" disabled /></td>
                                                        <td><input type="text" defaultValue="" placeholder="Yes/No" disabled /></td>
                                                        <td><input type="text" defaultValue="" placeholder="Customs Code" disabled /></td>
                                                        <td><input type="text" defaultValue="" placeholder="Message" disabled /></td>
                                                        </tr>

                                                ) : null}                                



                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>{orderDetails ? totalPriceFormatted("itempriceexctax") : ""}</td>
                                                    <td>{orderDetails ? totalPriceFormatted("itempricetax") : ""}</td>
                                                    <td>{orderDetails ? totalPriceFormatted("itempriceinctax") : ""}</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>

                                            </tbody>

                                            </table>
                                        </div>


                                    </GridItem>

                                </Grid>


                                { addingNewOrder?

                                <CreateButton title={loading ? "Saving..." : "Done"} size="l" type="submit" disabled={loading} />
                                    :
                                ""}


                            </form>

                        </>

                    )}

                </div>

            </div>


        </div>

    );
};

export default OrderDetailsPopup;