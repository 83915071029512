// Import react elements
import { useContext, useState, useEffect, useMemo } from "react";

// Import strich api calls
import { getOrders } from "api/APIStrichCalls";
import { getOrder } from "api/APIStrichCalls";

// Import context function
import { UserContext } from "global/context/UserContext";

// Import components
import { GridItem } from "global/components/Grid";
import LoadingDotsAnimation from "global/components/LoadingDotsAnimation"
import OrdersTable from "./OrdersTable";
import OrderDetailsPopUp from "./OrderDetailsPopUp";
import FulfilmentDetailsPopUp from "./FulfilmentDetailsPopUp";
import CreateButton from "global/components/CreateButton";




// Format the columns for the orders table - Need to set these values in fetchOrders mapped array
// Need to add something for parent child (linked) orders +

const initialTableColumns = [

    // Select tick box at left of table rows - if we want to multi select orders and action them
    //{ key: 'select', label: '', resizable: false, minWidth: 40, maxWidth: 40, draggable: false },

    // Warning symbol - if something is in the notes section?? Ask Ben to add 'warning' message

    { key: 'status', label: 'Status', resizable: true, minWidth: 75, maxWidth: 150, draggable: true },

    { key: 'isFulfilmentOrder', label: 'Fulfilment', resizable: true, minWidth: 75, maxWidth: 150, draggable: true },

    { key: 'shippingconfirmed', label: 'Shipped', resizable: true, minWidth: 70, maxWidth: 150, draggable: true },
    { key: 'id', label: 'Order ID', resizable: true, minWidth: 80, maxWidth: 300, draggable: true },
    { key: 'venueorderid', label: 'Venue ID', resizable: true, minWidth: 90, maxWidth: 300, draggable: true },
    { key: 'orderItemFirst', label: 'Order Items', resizable: true, minWidth: 150, maxWidth: 400, draggable: true },
    { key: 'creationdate', label: 'Creation Date', resizable: true, minWidth: 150, maxWidth: 400, draggable: true },
    { key: 'deliverycustname', label: 'Name', resizable: true, minWidth: 50, maxWidth: 150, draggable: true },

    // Add value (depending on normal order or fulfilment values xQty -- do maths in PrepareData function)

    { key: 'shippingcostinctax', label: 'Shipping', resizable: true, minWidth: 75, maxWidth: 150, draggable: true },

    // Add shipping currency - do look up in orderitems?

    { key: 'connectionname', label: 'Source', resizable: true, minWidth: 63, maxWidth: 150, draggable: true },

    // Produced ... per order item

    // Probably need a look up for this:
    { key: 'chosenshippingmethod', label: 'Shipping Method', resizable: true, minWidth: 120, maxWidth: 400, draggable: true },

    // Label

    // Ship Within

    { key: 'deliverycountry', label: 'Destination', resizable: true, minWidth: 88, maxWidth: 150, draggable: true },

    { key: 'batchid', label: 'Batch ID', resizable: true, minWidth: 69, maxWidth: 150, draggable: true },
];



const OrdersList = (token, authReady ) => {

    // Extract the connections data from the user context - allows us to use their data anywhere inside this component without needing to manually pass it down as a prop
    const { connectionsData } = useContext(UserContext);

    // Set empty array state for the orders data retrieved from the API
    const [ordersData, setOrdersData] = useState([]);

    // Set loading state - while api call is running
    const [loading, setLoading] = useState(true);

    // Set empty array state for selected order
    const [orderDetails, setOrderDetails] = useState([]);

    // Setnull state for adding new order
    const [addingNewOrder, setAddingNewOrder] = useState(null);


    // Get the orders data and prepare it for the table
    useEffect(() => {

        // Check if there's no token or if the authReady flag is false
        if (!authReady || !token)

            // Exit early
            return;


        const fetchOrders = async () => {

            setLoading(true);

            try {

                const response = await getOrders(token);

                if (response.success && Array.isArray(response.results)) {

                    const mapped = response.results.map((order) => {

                        const orderItems = Array.isArray(order.orderitems) ? order.orderitems : [];

                        const matchingConnection = connectionsData.find(connection => connection.id === order.connid);

                        const fulfilments = Array.isArray(order?.fulfilments) ? order.fulfilments : [];

                        const fulfilmentsOrderItems = fulfilments.flatMap(f =>
                            Array.isArray(f.orderitems) ? f.orderitems : []
                        );



                        return {

                            id: order.id,

                            orderitems: orderItems,
                            orderItemFirst: orderItems[0]?.title || 'nope',
                            orderItemRemaining: orderItems.slice(1).map((i) => i.title || ''),
                            expandRow: orderItems.length > 1 ? true : false,

                            status: order.status || 'unkown',
                            connectionname: matchingConnection?.connectionname || "Unknown Connection",
                            connid: order.connid,
                            creationdate: order.creationdate,

                            shippingconfirmed: order.shippingconfirmed,
                            venueorderid: order.venueorderid,
                            deliverycustname: order.deliverycustname,
                            shippingcostinctax: order.shippingcostinctax,
                            chosenshippingmethod: order.chosenshippingmethod,
                            deliverycountry: order.deliverycountry,
                            batchid: order.batchid,


                            fulfilments: fulfilments,
                            fulfilmentsOrderItems: fulfilmentsOrderItems,
                            isFulfilmentOrder: fulfilments?.length > 0 ? "Y" : "N",


                        };

                    });

                    setOrdersData(mapped);

                    //console.log("Orderslist api response: ", mapped);

                    //console.log(response);

                }
            } catch (err) {

                console.error("Orders API error:", err);

            } finally {

                setLoading(false);

            }

        };

        fetchOrders();

    }, [authReady, connectionsData, token]);



    const uniqueFilterOptions = useMemo(() => {

        const options = {};

        ordersData.forEach((row) => {

            ['age', 'status', 'type'].forEach((key) => {

                if (!options[key]) options[key] = new Set();

                if (row[key] !== undefined) options[key].add(row[key]);

            });

        });

        return Object.fromEntries(

            Object.entries(options).map(([key, set]) => [key, Array.from(set).sort()])

        );

    }, [ordersData]);





    const [popupOpen, setPopupOpen] = useState(false);
    const [loadingOrderDetails, setLoadingOrderDetails] = useState(false);
    const [error, setError] = useState("");

    const handleSelectOrder = async (orderId) => {

        //console.log(orderId)

        setPopupOpen(true);

        setLoadingOrderDetails(true);

        setError("");

        setOrderDetails(null);

        try {

            const response = await getOrder(token, orderId);

            if (response?.success) {

                setOrderDetails(response.results);

            } else {

                setError(response.error || "Unable to fetch order details.");

            }

        } catch (err) {

            setError("Network error");

            console.error(err);

        } finally {

            setLoadingOrderDetails(false);

        }

    };





    // Add New Order button
    const handleCreateNewOrderButton = () => {

        // Clear the current selected order data
        setOrderDetails([]);

        // Set addingNewOrder to true
        setAddingNewOrder(true);

        // Open the pop up
        setPopupOpen(true);


    }




    const [fulfilmentDetails, setFulfilmentDetails] = useState([]);
    const [fulfilmentPopupOpen, setFulfilmentPopupOpen] = useState(false);



    // Handle request fulfilment button
    const handleRequestFulfilment = (orderDetails) => {

        /*
        if (!orderDetails?.fulfilments) {

            console.log("No fulfilments found");
            return;
        }

        orderDetails.fulfilments.forEach((fulfilment, fulfilmentIndex) => {
            if (Array.isArray(fulfilment.order?.orderitems)) {
                fulfilment.order.orderitems.forEach((fulfilmentItem, itemIndex) => {
                    console.log(`Fulfilment ${fulfilmentIndex} - Item ${itemIndex}:`, fulfilmentItem);
                });
            } else {
                console.log(`Fulfilment ${fulfilmentIndex} has no orderitems.`);
            }
        });
        */

        setFulfilmentDetails(orderDetails.fulfilments);

        // Display a pop up with editable fulfilment details
        setFulfilmentPopupOpen(true);

    };





    return (

        <GridItem>

            <h3>Orders</h3>

            <br />

            {!loading && ordersData.length > 0 ?

                <>

                    <CreateButton title="+ Add New Order" size="l" onClick={handleCreateNewOrderButton} />

                    <OrdersTable initialTableColumns={initialTableColumns} ordersData={ordersData} uniqueFilterOptions={uniqueFilterOptions} handleSelectOrder={handleSelectOrder} />

                    <OrderDetailsPopUp
                        isOpen={popupOpen}
                        onClose={() => setPopupOpen(false)}
                        loadingOrderDetails={loadingOrderDetails}
                        error={error}
                        orderDetails={orderDetails}
                        addingNewOrder={addingNewOrder}
                        setAddingNewOrder={setAddingNewOrder}
                        handleRequestFulfilment={handleRequestFulfilment}
                    />


                    <FulfilmentDetailsPopUp
                        isOpen={fulfilmentPopupOpen}
                        onClose={() => setFulfilmentPopupOpen(false)}
                        orderDetails={orderDetails}

                    />



                </>

                :

                <span>

                    Loading<LoadingDotsAnimation />

                </span>

            }


            

            <table>

           

            </table>







        </GridItem>

    );

};

export default OrdersList;