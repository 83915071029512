// Import react elements
import { useContext } from "react";

// Import strich api calls
import { getFulfilmentCost } from "api/APIStrichCalls";
import { sendForFulfilment } from "api/APIStrichCalls";


// Import context function
import { AuthContext } from "global/context/AuthContext";

// Import components
import CreateButton from "global/components/CreateButton";

// Import utility functions
import { DisplayConfirmationDialog } from "global/utilities/DialogBox";



const FulfilmentDetailsPopup = ({ isOpen, onClose, orderDetails }) => {



    // Get the authentication token from the AuthContext (determines whether the user is logged in)
    const { token } = useContext(AuthContext);



    const handleGetFulfilmentCost = async (orderID) => {

        try {const response = await getFulfilmentCost(token, orderID);

            if (response.success) {

                //alert("This is going to cost " + response.response + "Are you sure you want to send it for fulfilment?");

                // It's how much??!

                // Display a confrimation dialog
                DisplayConfirmationDialog(

                    // Dialog message
                    "This is going to cost " + response.response + " Are you sure you want to send it for fulfilment?",

                    // Confirm button title
                    "Yes",

                    // Cancel button title - empty because we don't want a cancel button
                    "No",

                    // Returned response from the dialog box
                    function (is_confirmed) {

                        // Check if the response is TRUE
                        if (is_confirmed) {

                            // It is

                            // Send for fulfilment
                            sendForFulfilment(token, orderDetails.id, []);

                        }

                    }

                );


            } else {

                alert("no fulfilment for you");

            }
        }

        catch (error) {
                console.error("API error:", error);
                alert("Something went wrong.");
            }

    };





    // If isOpen is false return early (don't show the pop up)
    if (!isOpen) return null;


    // Else show the pop up
    return (

        <>

            <div className="popup-overlay">

                <div className="popup-container popup-container--medium">

                    <button className="popup-close" onClick={onClose}>X</button>

                    <h2>Fulfilment Details</h2>

                    <br />

                    <div className="popup-content">



                        <form>

                            <table>
                                
                                <thead>

                                    <tr>

                                        <th>Title</th>
                                        <th>SKU</th>
                                        <th>Qty</th>
                                    </tr>

                                </thead>
                                                                   
                                <tbody>

                                    {Array.isArray(orderDetails?.fulfilments) &&

                                        orderDetails.fulfilments.flatMap((fulfilment, fulfilmentIndex) =>
                                            Array.isArray(fulfilment.order?.orderitems)
                                                ? fulfilment.order.orderitems.map((fulfilmentItem, itemIndex) => (

                                                    <tr key={`f${fulfilmentIndex}-i${itemIndex}`}>
                                                        <td><input type="text" defaultValue={fulfilmentItem.title || ""} placeholder="Title" disabled /></td>
                                                        <td><input type="text" defaultValue={fulfilmentItem.sku || ""} placeholder="SKU" disabled /></td>
                                                        <td><input type="text" defaultValue={fulfilmentItem.orderquantity || ""} placeholder="0" disabled /></td>
                                                    </tr>

                                                ))

                                                : 

                                                <tr>
                                                <td>nope</td>

                                                </tr>
                                        )
                                    }

                                </tbody>


                            </table>


                            <CreateButton title="Fulfil Me!" size="l" onClick={() => handleGetFulfilmentCost(orderDetails.id)} />


                        </form>




                    </div>

                </div>

            </div>



        </>

    )

}

export default FulfilmentDetailsPopup;