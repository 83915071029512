
// Alter these to flick between live and dev - touch nothing else

let api_path = "php/"
//let api_path = "http://localhost:8000/php/"
//let api_path = "http://192.168.178.45:8000/php/"



export const loginUser = async (username, password) => {

    // Now make the call
    try {
        const result = await fetch(api_path + 'strich_auth.php', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'username': username,
                'password': password },
            body: JSON.stringify({ username, password }),
        });

        const response = await result.json();

        return response;

    } catch (error) {

        console.error("loginUser API Error:", error);

        // Return a safe error object
        return { error: "Network error" }; 
    }
};



export const registerNewAccount = async (formValues) => {

    const { email, password, referred_by } = formValues;

    // Construct the exact shape the API needs from the formValues we have
    const postdata = { email, password, };

    // This only includes the referred_by field if it exists and has a value — otherwise, it’s omitted
    if (referred_by?.trim()) {

        postdata.referred_by = referred_by.trim();

    }

    // Now make the call with our correctly formatted postdata
    try {
        const result = await fetch(api_path + 'api_router.php/?action=create_account', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
            body: JSON.stringify(postdata)
    });

        const response = await result.json();

        return response;

    } catch (error) {

        console.error("registerNewAccount API Error:", error);

        // Return a safe error object
        return { error: "Network error" };
    }
};



export const activateNewAccount = async (username, token) => {

    let postdata = ""

    // Now make the call
    try {
    const result = await fetch(api_path + 'api_router.php/?action=activate_account&email=' + username + "&token=" + token, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: postdata
    });

        const response = await result.json();

        return response;

    } catch (error) {

        console.error("registerNewAccount API Error:", error);

        // Return a safe error object
        return { error: "Network error" }; 
    }
};



export const resetPassword = async (formValues) => {

    // Set the value for email from the formValues 
    const email = Object.values(formValues)[0];

    // Now make the call
    try {
        const result = await fetch(api_path + 'api_router.php/?action=reset_password&email=' + email, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const response = await result.json();
        //console.log(response);

        return response;
        

    } catch (error) {

        console.error("resetPassword API Error:", error);

        // Return a safe error object
        return { error: "Network error" };
    }
};



export const updatePassword = async (formValues) => {

    // Set the postdata 
    let update_data = [];
    update_data["email"] = formValues.email
    update_data["new_password"] = formValues.password
    update_data["token"] = formValues.token

    // Convert to JSON
    let postdata = JSON.stringify(Object.assign({}, update_data))

    // Now make the call
    try {
            const result = await fetch(api_path + 'api_router.php/?action=update_password', {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
            },
            body: postdata
        });

        const response = await result.json();

        return response;

    } catch (error) {

        console.error("updatePassword API Error:", error);

        // Return a safe error object
        return { error: "Network error" };
    }

}



export const requestEmailChange = async (userEmail, token) => {

    // Set the value for email from the formValues 
    const email = Object.values(userEmail)[0];

    // Now make the call
    try {
        const result = await fetch(api_path + 'api_router.php/?action=request_email_change&email=' + email + "&token=" + token, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const response = await result.json();
        //console.log(response);

        return response;

    } catch (error) {

        console.error("resetPassword API Error:", error);

        // Return a safe error object
        return { error: "Network error" };
    }
};



export const updateEmailAddress = async (formValues) => {

    // Set the postdata 
    let update_data = [];
    update_data["email"] = formValues.oldEmail
    update_data["new_email"] = formValues.newEmail
    update_data["token"] = formValues.token

    // Convert to JSON
    let postdata = JSON.stringify(Object.assign({}, update_data))

    // Now make the call
    try {
        const result = await fetch(api_path + 'api_router.php/?action=update_email', {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
            },
            body: postdata
        });

        const response = await result.json();
        //console.log("updateEmailAddress response: ", response);

        return response;

    } catch (error) {

        console.error("updatePassword API Error:", error);

        // Return a safe error object
        return { error: "Network error" };
    }

}











export const strichCall = async (method, endpoint, token, postdata, set_token) => {

    //console.log(postdata);

    // Now make the call
    try {
        const result = await fetch('https://api.strichsuite.com/test/' + endpoint, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'X-Strich-Auth-Token': token
        },
        body: postdata
    });

        // DEBUGGING SYNTAX ERRORS
        //const responseText = await result.text();  // instead of response.json()
        // console.log("Raw response:", responseText);  // inspect this

        const response = await result.json();

        //console.log(response);

    //check if the portal or API is offline/online

    /*
    let offline_alert_shown = false;

    //if an api call fails with an offline message then give an alert to the user while it waits for the maintenance page to appear
    if (endpoint !== "status" && (response.error_message === "Portal Offline" || response.error_message === "API Offline")) {

        if (!offline_alert_shown) {
            alert("The portal appears to be offline, we're sorry for any inconvenience caused. Please check back later.");
            //display_confirmation_dialog_single_button("The portal appears to be offline, we're sorry for any inconvenience caused. Please check back later.", "OK");
            offline_alert_shown = true; //set the flag to true to indicate that the alert has been shown
        }
    };

    //we only want to monitor the 'status' call (not all api calls!) - NB: because it's the only call that passes in the set_token function
    if (endpoint === "status" && (response.error_message === "Portal Offline" || response.error_message === "API Offline")) {

        //it's offline so clear the token and replace it with token='offline'
        set_token("offline");

        //we only want to monitor the 'status' call (not all api calls!)
    } else if (endpoint === "status") {

        //it's online

        //check if the current token is 'offline'
        if (token === "offline") {

            //if so set it to false (for the log in page)
            set_token(false)

        } else {

            //it's not so set it to the token for that user's session
            set_token(token);

        }

        offline_alert_shown = true; //set the flag to true to indicate that the alert has been shown

    };

    */

        return response;

    //catch for errors and console.log them with the function name so we know where it's coming from
    } catch (error) {

        console.error("strichCall - Error in: ", endpoint, " Error message: ", error);

        // Return a safe error object
        return { error: "Network error" };
    }


}