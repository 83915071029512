// Import react elements
import { useEffect, useState } from "react";

// Import css
import "./LoadingDotsAnimation.css";



const LoadingDotsAnimation = ({ size = "1em",

    speed = 300,

    withSpinner = false,

    color = "var(--primary-color)" // uses theming automatically

}) => {

    const [dots, setDots] = useState("");

    useEffect(() => {

        const interval = setInterval(() => {

            setDots((prev) => (prev.length >= 3 ? "" : prev + "."));

        }, speed);

        return () => clearInterval(interval);

    }, [speed]);

    return (

        <span
            className="loading-dots-wrapper"
            style={{ fontSize: size, color }}
        >

            {withSpinner && <span className="spinner" />}

            <span className="loading-dots">{dots}</span>

        </span>

    );

};

export default LoadingDotsAnimation;