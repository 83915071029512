import React, { useState, useRef, useEffect, useMemo } from 'react';



const Table = ({ initialTableColumns, ordersData, uniqueFilterOptions, handleSelectOrder }) => {

    //console.log("log: ", ordersData);

    const [columns, setColumns] = useState(initialTableColumns);
    //const [data, setData] = useState(sampleData);
    const [columnWidths, setColumnWidths] = useState({});
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [filters, setFilters] = useState({});
    const [textFilters, setTextFilters] = useState({});
    const [selectedRows, setSelectedRows] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const dragColIndex = useRef(null);



    const filteredData = useMemo(() => {
        return ordersData.filter((row) => {
            const matchesCheckboxFilters = Object.entries(filters).every(([key, values]) => {
                if (!Array.isArray(values) || values.length === 0) return true;
                return values.includes(row[key]);
            });

            const matchesTextFilters = Object.entries(textFilters).every(([key, val]) => {
                if (!val) return true;
                return String(row[key] ?? '').toLowerCase().includes(val.toLowerCase());
            });

            return matchesCheckboxFilters && matchesTextFilters;
        });
    }, [ordersData, filters, textFilters]);



    const renderSidebarFilters = () => (
        <div style={{ padding: '10px', minWidth: '200px', borderRight: '1px solid #ccc' }}>
            <h4>Filters</h4>
            {Object.entries(uniqueFilterOptions).map(([key, options]) => (
                <div key={key} style={{ marginBottom: '10px' }}>
                    <strong>{key.charAt(0).toUpperCase() + key.slice(1)}</strong>
                    {options.map((option) => (
                        <div key={option}>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={(filters[key] || []).includes(option)}
                                    onChange={(e) => {
                                        setFilters((prev) => {
                                            const currentValues = prev[key] || [];
                                            if (e.target.checked) {
                                                return {
                                                    ...prev,
                                                    [key]: [...currentValues, option],
                                                };
                                            } else {
                                                return {
                                                    ...prev,
                                                    [key]: currentValues.filter((val) => val !== option),
                                                };
                                            }
                                        });
                                    }}
                                />{' '}
                                {option}
                            </label>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );

    const renderColumnTextFilters = () => (
        <tr>
            {columns.map((col) => (
                <th key={col.key} style={{ padding: '4px' }}>
                    {col.key !== 'select' && (
                        <input
                            type="text"
                            value={textFilters[col.key] || ''}
                            onChange={(e) =>
                                setTextFilters((prev) => ({ ...prev, [col.key]: e.target.value }))
                            }
                            placeholder={`Filter ${col.label}`}
                            style={{ width: '90%' }}
                        />
                    )}
                </th>
            ))}
        </tr>
    );








    useEffect(() => {
        const widths = {};
        columns.forEach(col => {
            widths[col.key] = col.minWidth || 150;
        });
        setColumnWidths(widths);
    }, [columns]);



    const handleDragStart = (index) => {
        dragColIndex.current = index;
    };



    const handleDrop = (dropIndex) => {
        const dragIndex = dragColIndex.current;
        if (dragIndex === null || dragIndex === dropIndex) return;

        const newCols = [...columns];
        const [removed] = newCols.splice(dragIndex, 1);
        newCols.splice(dropIndex, 0, removed);

        const fixed = newCols.filter(col => col.draggable === false);
        const reorderable = newCols.filter(col => col.draggable !== false);
        setColumns([...fixed, ...reorderable]);
        dragColIndex.current = null;
    };




    const toggleRowExpansion = (id) => {
        setExpandedRows((prev) =>
            prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
        );
    };



    const toggleRowSelection = (id) => {
        setSelectedRows(prev =>
            prev.includes(id) ? prev.filter(i => i !== id) : [...prev, id]
        );
    };



    const toggleSelectAll = (paginated) => {
        const allSelected = paginated.every(row => selectedRows.includes(row.id));
        setSelectedRows(allSelected ? [] : paginated.map(row => row.id));
    };



    const handleResize = (e, key) => {

        e.preventDefault();
        const startX = e.clientX;
        const startWidth = columnWidths[key];
        const col = columns.find(c => c.key === key);

        const onMouseMove = (e) => {
            let newWidth = startWidth + (e.clientX - startX);
            if (col.minWidth) newWidth = Math.max(newWidth, col.minWidth);
            if (col.maxWidth) newWidth = Math.min(newWidth, col.maxWidth);
            setColumnWidths(prev => ({ ...prev, [key]: newWidth }));
        };

        const onMouseUp = () => {
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);
        };

        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('mouseup', onMouseUp);
    };







    const sortedData = [...filteredData].sort((a, b) => {
        if (!sortConfig.key) return 0;
        const aVal = a[sortConfig.key];
        const bVal = b[sortConfig.key];
        if (aVal < bVal) return sortConfig.direction === 'asc' ? -1 : 1;
        if (aVal > bVal) return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
    });



    const paginatedData = sortedData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);
    const totalPages = Math.ceil(sortedData.length / rowsPerPage);



    return (

        <div style={{ overflowX: 'auto', minWidth: '100%' }}>

            {renderSidebarFilters()}

            <div className="table-container">

            <table style={{ borderCollapse: 'collapse', minWidth: 'auto', tableLayout: 'fixed' }}>
                <thead>
                    <tr>
                        {columns.map((col, index) => (
                            <th
                                key={col.key}
                                draggable={col.draggable !== false}
                                onDragStart={() => col.draggable !== false && handleDragStart(index)}
                                onDragOver={(e) => col.draggable !== false && e.preventDefault()}
                                onDrop={() => col.draggable !== false && handleDrop(index)}
                                style={{ 
                                    position: 'relative',
                                    width: columnWidths[col.key],
                                    minWidth: col.minWidth,
                                    maxWidth: col.maxWidth,
                                    borderBottom: '1px solid #ccc',
                                    padding: '8px',
                                    whiteSpace: 'nowrap',
                                    
                                    cursor: col.draggable !== false ? 'move' : 'default',
                                }}
                            >
                                {col.key === 'select' ? (
                                    <input
                                        type="checkbox"
                                        onChange={() => toggleSelectAll(paginatedData)}
                                        checked={
                                            paginatedData.length > 0 &&
                                            paginatedData.every(row => selectedRows.includes(row.id))
                                        }
                                    />
                                ) : (
                                    <div
                                        onClick={() => setSortConfig(prev => ({
                                            key: col.key,
                                            direction: prev.key === col.key && prev.direction === 'asc' ? 'desc' : 'asc'
                                        }))}
                                        style={{ cursor: 'pointer', userSelect: 'none' }}
                                    >
                                        {col.label} {sortConfig.key === col.key ? (sortConfig.direction === 'asc' ? '<' : '>') : ''}
                                    </div>
                                )}
                                {col.resizable && (
                                    <div
                                        onMouseDown={(e) => handleResize(e, col.key)}
                                        className="resize-handle"
                                    />
                                )}
                            </th>
                        ))}
                    </tr>

                    {renderColumnTextFilters()}

                </thead>
                <tbody>

                    {paginatedData.map((row) => (

                        <React.Fragment key={row.id}>

                            <tr onDoubleClick={() => handleSelectOrder(row.id)}>

                                {columns.map((col) => (

                                    <td key={col.key}>

                                        {col.key === 'select' ?

                                            (
                                                <input
                                                    type="checkbox"
                                                    checked={selectedRows.includes(row.id)}
                                                    onChange={() => toggleRowSelection(row.id)}
                                                />
                                            )

                                            :

                                            col.key === 'orderItemFirst' && row.expandRow ?

                                                (

                                                    <span
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => toggleRowExpansion(row.id)}
                                                    >
                                                        {expandedRows.includes(row.id) ? '<' : '>'} {row[col.key]}

                                                    </span>

                                                )

                                                :

                                                col.key === 'orderItemFirst' && !row.expandRow ?

                                                    (

                                                        <span style={{ marginLeft: '10px' }}> {row[col.key]} </span>

                                                    )

                                                    :

                                                (

                                                    <span> {row[col.key]} </span>

                                                )}

                                    </td>

                                ))}

                            </tr>

                            {row.expandRow && expandedRows.includes(row.id) && (

                                <tr>
                                    <td></td>
                                    <td colSpan={columns.length - 1} style={{ backgroundColor: '#f9f9f9' }}>

                                        {row.orderItemRemaining || 'No order items'}

                                    </td>
                                </tr>

                            )}

                        </React.Fragment>
                    ))}
                </tbody>
                </table>

            </div>

            <div style={{ marginTop: '10px' }}>
                <button onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))} disabled={currentPage === 1}>Prev</button>
                <span style={{ margin: '0 10px' }}>Page {currentPage} of {totalPages}</span>
                <button onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages}>Next</button>

                <select value={rowsPerPage} style={{width: "auto", marginLeft: "10px" }} onChange={(e) => setRowsPerPage(Number(e.target.value))}>
                    {[10, 50, 100, 500].map((val) => (
                        <option key={val} value={val}>{val} rows</option>
                    ))}
                </select>

            </div>
        </div>
    );
};

export default Table;
